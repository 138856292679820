import React from "react";
import "../../style/css/custom.css";
import ShortInfoSection from "./ShortInfoSection";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useState, useEffect } from "react";
import { appUrls } from "../../config/Constants";

export default function InfoSection() {
    const [userCount, setUserCount] = useState(0);
    const [orderCount, setOrderCount] = useState(0);
    const [downloadCount, setDownloadCount] = useState(0);

    // Function that retrieves the number of users from the backend endpoint /users/amount and returns it:
    function getNumberOfUsers() {
        return fetch(appUrls.api_backend + "users/amount")
            .then((res) => res.json())
            .then((data) => {
                if (data?.amount) {
                    setUserCount(data.amount);
                }
            });
    }

    // Function that retrieves the number of orders from the backend endpoint /orders/amount and returns it:
    function getNumberOfOrders() {
        return fetch(appUrls.api_backend + "orders/amount")
            .then((res) => res.json())
            .then((data) => {
                if (data?.amount) {
                    setOrderCount(data.amount);
                }
            });
    }

    // Function that retrieves the number of downloads from the backend endpoint /orders/download/amount and returns it:
    function getNumberOfDownloads() {
        return fetch(appUrls.api_backend + "orders/download/amount")
            .then((res) => res.json())
            .then((data) => {
                if (data?.amount) {
                    setDownloadCount(data.amount);
                }
            });
    }

    useEffect(() => {
        getNumberOfUsers();
        getNumberOfOrders();
        getNumberOfDownloads();
    }, []);

    return (
        <div id="learn-more" className="col-12 bg-white">
            <section className="row justify-content-center align-items-center ptb_100">
                <div className={"col-md-6 col-sm-10"}>
                    <div className={"p-4"}>
                        <h1>Translate Your Content In No Time.</h1>
                        <h4>
                            Never worry about translating your systems. A few steps and you're set.
                        </h4>
                    </div>
                    <section className={"row"}>
                        <div className="col-xl-3 col-xxl-3 col-lg-3 col-sm-6">
                            <div className="media-body text-center">
                                <i className="fas fa-users text-warning fs-36"></i>
                                <h3 className="mb-0">Users</h3>
                                <h1 className="mb-0 text-warning">
                                    {userCount}+
                                </h1>
                            </div>
                        </div>

                        <div className="col-xl-3 col-xxl-3 col-lg-3 col-sm-6">
                            <div className="media-body text-center">
                                <i className="fas fa-globe text-info fs-36"></i>
                                <h3 className="mb-0">Translations</h3>
                                <h1 className="mb-0 text-info">
                                    {orderCount}+
                                </h1>
                            </div>
                        </div>

                        <div className="col-xl-3 col-xxl-3 col-lg-3 col-sm-6">
                            <div className="media-body text-center">
                                <i className="fas fa-file-download text-success fs-36"></i>
                                <h3 className="mb-0">Downloads</h3>
                                <h1 className="mb-0 text-success">
                                    {downloadCount}+
                                </h1>
                            </div>
                        </div>
                    </section>
                </div>
                <div className={"col-md-6 col-sm-10"}>
                    <div className="">
                        <div className="card-header border-0 pb-0">
                            <h3 className="">
                                Few steps like promised ;){" "}
                            </h3>
                        </div>
                        <div className="card-body">
                            <PerfectScrollbar
                                style={{ height: "550px" }}
                                id="DZ_W_TimeLine"
                                className="widget-timeline dlab-scroll height370 ps ps--active-y"
                            >
                                <ul className="timeline">
                                    <li>
                                        <div className="timeline-badge warning"></div>
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="/widget-basic"
                                        >
                                            <span>#step 1</span>
                                            <h3>Start an order</h3>
                                            <h6 className="mb-0">
                                                Click on 'try now' or make an
                                                account first.{" "}
                                            </h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="timeline-badge info"></div>
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="/widget-basic"
                                        >
                                            <span>#step 2</span>
                                            <h3>Fill in your texts</h3>
                                            <h6 className="mb-0">
                                                Copy all the texts of your
                                                website.{" "}
                                            </h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="timeline-badge danger"></div>
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="/widget-basic"
                                        >
                                            <span>#step 3</span>
                                            <h3>Choose target languages</h3>
                                            <h6 className="mb-0">
                                                Pick languages from a list of 100+ supported languages.{" "}
                                            </h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="timeline-badge success"></div>
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="/widget-basic"
                                        >
                                            <span>#step 4</span>
                                            <h3>Translation is finished</h3>
                                            <h6 className="mb-0">
                                                Your files are ready for
                                                download.{" "}
                                            </h6>
                                        </Link>
                                    </li>
                                </ul>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
