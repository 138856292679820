import React from "react";

import {Link} from "react-router-dom";

import profile from "../../style/images/user.jpg";
import {Dropdown} from "react-bootstrap";
import LogoutPage from './Logout';

const Header = () => {
    var path = window.location.pathname.split("/");
    var name = path[path.length - 1].split("-");
    var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
    var finalName = filterName.includes("app") ? filterName.filter((f) => f !== "editor") : filterName;

    return (
        <div className="header border-bottom">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div
                                className="dashboard_bar"
                                style={{textTransform: "capitalize"}}
                            >
                                {finalName.join(" ").length === 0
                                    ? "Dashboard"
                                    : finalName.join(" ") === "dashboard dark"
                                        ? "Dashboard"
                                        : finalName.join(" ")}
                            </div>
                        </div>
                        <ul className="navbar-nav header-right">


                            <Dropdown as="li" className="nav-item dropdown header-profile">
                                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                                    {/*<img src={profile} width={20} alt=""/>*/}
                                    <i className="fas fa-user-alt h1 fs-30 text-primary"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
                                    {/*<Link to="/my-profile" className="dropdown-item ai-icon">*/}
                                    {/*    <svg*/}
                                    {/*        id="icon-user1" xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*        className="text-primary me-1"*/}
                                    {/*        width={18} height={18} viewBox="0 0 24 24" fill="none"*/}
                                    {/*        stroke="currentColor" strokeWidth={2} strokeLinecap="round"*/}
                                    {/*        strokeLinejoin="round"*/}
                                    {/*    >*/}
                                    {/*        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>*/}
                                    {/*        <circle cx={12} cy={7} r={4}/>*/}
                                    {/*    </svg>*/}
                                    {/*    <span className="ms-2">Profile </span>*/}
                                    {/*</Link>*/}
                                    <LogoutPage/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Header;
