export const appUrls = {
    api_backend: "https://translate-backend-prod.onrender.com/",
    // api_backend: "http://localhost:3002/", // dev
};

export const appLocalStorageValues = {
    language: "language",
    user_data: "user_data",
};

export const appErrorMessages = {
    choose_language_required: "Please choose a language",
    words_data_required: "You didnt put any words in the fields",
    all_fields_are_required: "All fields are required",
    password_match: "Passwords do not match",
    json_format_not_good: "Json format is not correct",
    only_5_sentences: "Only 5 sentences are allowed for this sample",
    only_2_languages: "Only 2 languages are allowed for this sample",
};

export const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    limit: 5,
};
