import React from "react";
import Languages from "../../CustomDB/Languages.json";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getLanguageLabelByLanguageCode } from "../../utils/SharedFunctions";
import {Link} from "react-router-dom";

export default function Step4({
    texts,
    sourceLanguage,
    targetLanguages,
    goBack,
    goNextStep,
    loading,
    termsAccept,
    setTermsAccept
}) {
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-10 mb-2 ">
                        <h2 className="text-left">Overview</h2>

                        <div className={"row"}>
                            <div className={"col-lg-6 col-sm-12"}>
                                <div className="w-100 mb-3">
                                    <div className="form-group mb-3">
                                        <div className="card border-0 pb-0">
                                            <div className="card-body border-top shadow rounded">
                                                <strong
                                                    className={"text-primary"}
                                                >
                                                    Sentences
                                                </strong>
                                                <PerfectScrollbar
                                                    style={{ height: "370px" }}
                                                    id="DZ_W_Todo2"
                                                    className="widget-media overflow-auto height370 ps ps--active-y mt-3"
                                                >
                                                    <ul className="timeline">
                                                        {texts.map(
                                                            (item, i) => {
                                                                return (
                                                                    <li
                                                                        key={i}
                                                                        className={
                                                                            "cursor-pointer"
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                "timeline-panel"
                                                                            }
                                                                        >
                                                                            <div className="media-body">
                                                                                <h5 className="mb-1">
                                                                                    {i +
                                                                                        1 +
                                                                                        ". " +
                                                                                        item}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-lg-6 col-sm-12"}>
                                <div className="card border-top shadow">
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong
                                                    className={"text-primary"}
                                                >
                                                    Source language
                                                </strong>
                                                <span className="m-1 text-right">
                                                    {getLanguageLabelByLanguageCode(
                                                        sourceLanguage
                                                    ) ?? ""}
                                                </span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong
                                                    className={"text-primary"}
                                                >
                                                    Target languages
                                                </strong>
                                                <span className="m-1  d-flex flex-column justify-content-end text-right">
                                                    {targetLanguages.map(
                                                        (item, i) => {
                                                            return (
                                                                <span
                                                                    key={i}
                                                                    className="m-1 text-right"
                                                                >
                                                                    {item.label}
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer pt-0 pb-0 text-center">
                                        <div className="row">
                                            <div className="col-4 pt-3 pb-3 border-right">
                                                <h3 className="mb-1 text-primary text-uppercase">
                                                    {targetLanguages.length}
                                                </h3>
                                                <span className={"fw-bold"}>
                                                    Targeted languages
                                                </span>
                                            </div>
                                            <div className="col-4 pt-3 pb-3 border-right">
                                                <h3 className="mb-1 text-primary text-uppercase">
                                                    {texts.toString().length}
                                                </h3>
                                                <span className={"fw-bold"}>
                                                    Character count
                                                </span>
                                            </div>
                                            <div className="col-4 pt-3 pb-3">
                                                <h3 className="mb-1 text-primary">
                                                    {"€" + priceInTotal()}
                                                    <a className={"fs-6"}>exl</a>
                                                </h3>
                                                <span className={"fw-bold"}>
                                                    Price
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={"col-lg-6 col-sm-12"}>
                                <div className="w-100 mb-3">
                                    <div className="form-group mb-3 h4">
                                        <input type={"checkbox"} checked={termsAccept} onChange={()=> setTermsAccept()}></input>
                                        <label className={"ps-2"}> I have read and accept the <Link className={"text-primary"} to="/page-disclaimer" target="_blank" rel="noreferrer">Terms & conditions</Link> </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button
                    className="btn btn-secondary sw-btn-prev me-1"
                    onClick={() => goBack()}
                    disabled={loading}
                >
                    Prev
                </button>
                <button
                    disabled={loading || !termsAccept}
                    className="btn btn-primary sw-btn-next ms-1"
                    onClick={() => {
                        goNextStep();
                    }}
                >
                    Confirm and go check out
                </button>
            </div>
        </>
    );

    function priceInTotal() {
        // Check how many 10000 char in the whole texts
        let pricePerCharacter = texts.toString().length / 40000;
        // add plus one to the count correct
        pricePerCharacter = parseInt(pricePerCharacter) + 1;
        // Calculate the total price
        pricePerCharacter = pricePerCharacter * 10 * targetLanguages.length;
        return pricePerCharacter;
    }
}
