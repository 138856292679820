/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../config/context/ThemeContext";

import profile from "../../style/images/user.jpg";
import {removeDataLocalStorage} from "../../utils/LocalStorageFunctions";
import {appLocalStorageValues} from "../../config/Constants";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);


  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);

  }, []);
 //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu

     let deshBoard = [
      "",
      "dashboard-dark",
      "guest-list",
      "guest-details",
      "concierge-list",
      "room-list",
       "task",
    ];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">

            {/*Dashboard*/}
		  <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="/dashboard" >
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            {/*<ul >*/}
            {/*  <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard</Link></li>*/}
            {/*  /!*<li><Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> Dashboard Dark</Link></li>*!/*/}
            {/*</ul>*/}
          </li>

            {/*<li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>*/}
            {/*    <Link className="has-arrow" to="#" >*/}
            {/*        <i className="fas fa-user-cog"></i>*/}
            {/*        <span className="nav-text">Orders</span>*/}
            {/*    </Link>*/}
            {/*    <ul >*/}
            {/*        <li><Link className={`${path === "my-profile" ? "mm-active" : ""}`} to="/my-profile"> Profile</Link></li>*/}

            {/*    </ul>*/}
            {/*</li>*/}


        </MM>

		<div className="copyright">
            <p className="fs-14 fw-bold text-center">Made with <span className="heart"></span> by    <a href="http://tagdev.nl" target="_blank" rel="noreferrer">Tagdev</a></p>
		</div>
      </PerfectScrollbar>
    </div>
  );
    async function onLogout() {
        await removeDataLocalStorage(appLocalStorageValues.user_data)
        window.location = "/"
        window.location.reload();
    }
};

export default SideBar;
