import { getDataLocalStorage } from "./LocalStorageFunctions";
import { appLocalStorageValues } from "../config/Constants";
import React from "react";
import Languages from "../CustomDB/Languages.json";

export function hasAValue(value) {
    if (value !== null && value !== undefined) {
        return true;
    } else {
        return false;
    }
}

export function getLanguage() {
    // let language = getDataLocalStorage(appLocalStoargeValues.language)
    let language = undefined;
    if (!hasAValue(language)) {
        return "nl";
    } else {
        return language;
    }
}

export async function userLoggedIn() {
    let userData = await getDataLocalStorage(appLocalStorageValues.user_data);
    if (hasAValue(userData)) {
        return true;
    } else {
        return false;
    }
}

export async function getUserData() {
    let userData = await getDataLocalStorage(appLocalStorageValues.user_data);
    if (hasAValue(userData)) {
        return userData;
    } else {
        return {};
    }
}

export function convertDateToView(value) {
    return new Date(parseInt(value)).toLocaleDateString();
}

export function getLanguageLabelByLanguageCode(language_code) {
    return (
        Languages?.languages.filter((language) => {
            return language.value === language_code ?? "";
        })?.[0]?.label ?? ""
    );
}

export function convertTranslatedToView(value) {
    if (value === true) {
        return <span className="badge badge-rounded badge-success">Done</span>;
    } else {
        return (
            <span className="badge badge-rounded badge-warning">Not yet</span>
        );
    }
}

export function convertStatusToView(value) {
    if (hasAValue(value) && value.toString().includes("payment_received")) {
        return <span className="badge badge-rounded badge-success">Paid</span>;
    } else {
        return (
            <span className="badge badge-rounded badge-warning">Not paid</span>
        );
    }
}
