import React, {Fragment, useEffect, useState} from "react";
import {Stepper, Step} from "react-form-stepper";
import {getUserData, hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import {appErrorMessages, toastOptions} from "../../../config/Constants";
import {newFreeOrderCall, newOrderCall} from "../../../Api/ApiCalls";
import Step1Example from "../../../components/NewOrderWizardExample/Step1Example";
import Step2Example from "../../../components/NewOrderWizardExample/Step2Example";
import Step3Example from "../../../components/NewOrderWizardExample/Step3Example";
import Step4Example from "../../../components/NewOrderWizardExample/Step4Example";

export default function NewOrderExample() {
    const [goSteps, setGoSteps] = useState(1);
    const [sourceLanguage, setSourceLanguage] = useState(undefined);
    const [wordsData, setWordsData] = useState([]);
    const [targetLanguages, setTargetLanguages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [translating, setTranslating] = useState(undefined);


    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-12">
                    <h1 className="text-center">Please try out our tool</h1>
                    <div className="">
                        <div className="card-body">
                          <div className="form-wizard ">
                                {goSteps !== 4 && (
                                    <Stepper
                                        className="nav-wizard"
                                        activeStep={goSteps - 1}
                                        label={false}
                                    >
                                        <Step
                                            className="nav-link"
                                            style={{"border-style": "none"}}
                                            onClick={() => {
                                                hasAValue(loading) ? setGoSteps(1) : console.log("")
                                            }}
                                        />
                                        <Step
                                            className="nav-link"
                                            style={{"border-style": "none"}}
                                            onClick={() => {
                                                hasAValue(loading) ? setGoSteps(2) : console.log("")
                                            }}
                                        />
                                        <Step
                                            className="nav-link"
                                            style={{"border-style": "none"}}
                                            onClick={() => {
                                                hasAValue(loading) ? setGoSteps(3) : console.log("")
                                            }}
                                        />
                                    </Stepper>
                                )}

                                {/*sourceLanguage*/}
                                {goSteps === 1 && (
                                    <Step1Example
                                        sourceLanguage={sourceLanguage}
                                        setSourceLanguage={(item) =>
                                            setSourceLanguage(item)
                                        }
                                        goNextStep={() => goNextStep()}
                                    />
                                )}

                                {/*wordsData*/}
                                {goSteps === 2 && (
                                    <Step2Example
                                        wordsData={wordsData}
                                        setWordsData={(item) =>
                                            setWordsData(item)
                                        }
                                        goNextStep={() => goNextStep()}
                                        goBack={() => goBack()}
                                        showNext={true}
                                    />
                                )}

                                {/*targetLanguages*/}
                                {goSteps === 3 && (
                                    <Step3Example
                                        targetLanguages={targetLanguages}
                                        setTargetLanguages={(item) =>
                                            setTargetLanguages(item)
                                        }
                                        goNextStep={() => goNextStep()}
                                        goBack={() => goBack()}
                                    />
                                )}

                                {/*Check out*/}
                                {goSteps === 4 && (
                                    <>
                                        <Step4Example texts={wordsData} targetLanguages={targetLanguages}
                                                      sourceLanguage={sourceLanguage}
                                                      loading={loading}
                                                      translating={translating}
                                                      goNextStep={() => goNextStep()}
                                                      goBack={() => goBack()}
                                        />
                                    </>
                                )}

                            </div>

                            {/*{submittedForTranslating &&  <div className="form-wizard ">*/}
                            {/*</div>}*/}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    function goNextStep() {
        if (goSteps === 3) {
            newOrder();
        } else {
            setGoSteps(goSteps + 1);
        }
    }

    function goBack() {
        setGoSteps(goSteps - 1);
    }

    function newOrder() {

        if (
            hasAValue(sourceLanguage) &&
            hasAValue(wordsData) &&
            hasAValue(targetLanguages)
        ) {
            let targets = targetLanguagesToListOfCodes();
            let texts = wordsData;
            let data = { texts, targets};
            setLoading(true)
            newFreeOrderCall(data).then((results) => {
                setGoSteps(goSteps + 1);
                setTranslating(results.data)

            }).catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
        } else {
            toast.error(appErrorMessages.all_fields_are_required, toastOptions);
        }
    }

    function targetLanguagesToListOfCodes() {
        let targets = targetLanguages.map((item) => {
            return item?.value ?? "";
        });
        return targets;
    }
}
