import React from "react";
import {Link} from "react-router-dom";
import "../../style/css/custom.css";
import logo from "./../../style/images/app/favicon.png"
import {Dropdown} from "react-bootstrap";
import LogoutPage from "./Logout";
import {getDataLocalStorage} from "../../utils/LocalStorageFunctions";
import {appLocalStorageValues as appLocalStoargeValues} from "../../config/Constants";
import {hasAValue} from "../../utils/SharedFunctions";

export default function HeaderExternal() {
    let userData = getDataLocalStorage(appLocalStoargeValues.user_data)

    return (
        <header className="section header-area">
            <div className="main-header-area">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-md navbar-light justify-content-end">

                        {/*Small screen */}
                        <Dropdown as="li"  className="navbar-toggler nav-item dropdown header-profile">
                            <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                                <i className="fas navbar-toggler-icon h1 fs-24"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end dropdown-menu-header ">

                                <li className="nav-item mb-2">
                                    <Link to="/dashboard" className="nav-link fw-bold">Home</Link>
                                </li>
                                <li className="nav-item mb-2">
                                    {!hasAValue(userData) && <Link to="/page-login" className="nav-link fw-bold">Sign in</Link>}
                                    {hasAValue(userData) && <Link to="/dashboard" className="nav-link fw-bold">Dashboard</Link>}
                                </li>

                                {/*<li className="nav-item mb-2 ">*/}
                                {/*    <a className="nav-link fw-bold text-primary" >Voor Reizigers</a>*/}
                                {/*    <li><Link to="/page-travelers-offers" className="nav-link fw-bold">Wat bieden wij?</Link></li>*/}
                                {/*    <li><Link to="/page-travelers-works" className="nav-link fw-bold">Hoe werkt het?</Link></li>*/}
                                {/*    <li><Link to="/page-travelers-connected-tour-operators" className="nav-link fw-bold">Aangesloten Touroperators</Link></li>*/}

                                {/*</li>*/}

                            </Dropdown.Menu>
                        </Dropdown>


                        {/*Big screen */}

                        <div className="collapse navbar-collapse justify-content-between">
                            {/* Logo */}
                            <a className="" href="/">
                                <img className="logo" src={logo} alt="" width={120}/>
                            </a>
                            {/* Header Items */}
                            <ul className="navbar-nav header-items ml-auto ">
                                <li className="nav-item">
                                    <Link to="/dashboard" className="nav-link fw-bold">Home</Link>
                                </li>
                                <li className="nav-item">
                                    {!hasAValue(userData) && <Link to="/page-login" className="nav-link fw-bold">Sign in</Link>}
                                    {hasAValue(userData) && <Link to="/dashboard" className="nav-link fw-bold">Dashboard</Link>}
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};
