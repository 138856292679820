import React, { Fragment, useEffect, useState } from "react";
import LastStep from "../../../components/NewOrderWizard/LastStep";
export default function TryAgain({match}) {

    useEffect(() => {

    }, []);


    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-wizard ">
                                <section>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 mb-2 text-center">
                                            <h2>Payment was not successful</h2>
                                            <a
                                                className={"btn btn-primary"}
                                                href={"/dashboard"}
                                            >
                                                Orders
                                            </a>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
