import React, { useEffect, useState } from "react";
import {
    retrieveOrderCall,
    translationCall,
    updateDownloadCountCall,
} from "../../../Api/ApiCalls";
import { toast } from "react-toastify";
import { toastOptions } from "../../../config/Constants";
import {
    getLanguageLabelByLanguageCode,
    hasAValue,
} from "../../../utils/SharedFunctions";
import Step2 from "../../../components/NewOrderWizard/Step2";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

export default function OrderOverview({ match }) {
    const [ref_id, setRef_id] = useState(match.params.id);
    const [item, setItem] = useState(undefined);
    const [downloadLink, setDownloadLink] = useState("");
    const [downloadEnable, setDownloadEnable] = useState(false);
    const [wordsData, setWordsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        launchLoadingModal();
        setRef_id(match.params.id);
        getOrder();
        
    }, []);

    useEffect(() => {
        if (hasAValue(item)){
            if(item?.status.includes("payment_received") && downloadLink)
                Swal.close();
            else if(!item?.status.includes("payment_received")){
                Swal.close();
            }
        }
        
    },[item, downloadLink]);  

    function launchLoadingModal(){
        // Show loading Swal
        Swal.fire({
            title: "Retrieving order...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
    }

    return (
        <>
            <div className={"row d-flex justify-content-end m-1 mb-3"}>
                <a
                    className={
                        downloadEnable
                            ? "btn col-2 btn-primary m-1"
                            : "btn col-2 btn-primary m-1 disabled"
                    }
                    onClick={updateDownloadCount}
                    href={downloadLink}
                    download="myjson.json"
                >
                    Download
                </a>
                {hasAValue(item) && item.status.includes("awaiting_payment") && (
                    <a
                        className="btn col-2 btn-secondary m-1"
                        onClick={() => {
                            window.open(item.payment_link);
                        }}
                        href="Javascript:void(0)"
                    >
                        proceed the order
                    </a>
                )}
            </div>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-12 mb-2 ">
                        {hasAValue(item) && (
                            <div className={"row"}>
                                <div className={"col-lg-12 col-sm-12"}>
                                    <div className="card border-top shadow">
                                        <div className="card-body pb-0">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item d-flex px-0 justify-content-between">
                                                    <strong
                                                        className={
                                                            "text-primary fs-16"
                                                        }
                                                    >
                                                        Source language
                                                    </strong>
                                                    <span className="m-1 text-right fw-bold fs-16">
                                                        {item.source_lang}
                                                    </span>
                                                </li>

                                                <li className="list-group-item d-flex px-0 justify-content-between">
                                                    <strong
                                                        className={
                                                            "text-primary fs-16"
                                                        }
                                                    >
                                                        Target languages
                                                    </strong>
                                                    <span className="m-1  d-flex flex-column justify-content-end text-right">
                                                        {item.target_langs.map(
                                                            (item2, i) => {
                                                                return (
                                                                    <span
                                                                        key={i}
                                                                        className="m-1 text-right fw-bold fs-16"
                                                                    >
                                                                        {getLanguageLabelByLanguageCode(
                                                                            item2
                                                                        )}
                                                                    </span>
                                                                );
                                                            }
                                                        )}
                                                    </span>
                                                </li>

                                                <li className="list-group-item d-flex px-0 justify-content-between">
                                                    <strong
                                                        className={
                                                            "text-primary fs-16"
                                                        }
                                                    >
                                                        Character count
                                                    </strong>
                                                    <span className="m-1 text-right fw-bold fs-16">
                                                        {
                                                            item.data.toString()
                                                                .length
                                                        }
                                                    </span>
                                                </li>

                                                <li className="list-group-item d-flex px-0 justify-content-between">
                                                    <strong
                                                        className={
                                                            "text-primary fs-16"
                                                        }
                                                    >
                                                        Price
                                                    </strong>
                                                    <span className="m-1 text-right fw-bold fs-16">
                                                        €{item.price / 100}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/*{(hasAValue(item) && item.status.includes("awaiting_payment")) &&  <div className={"col-lg-12 col-sm-12"}>*/}
                                {/*        <div className="form-group mb-3">*/}
                                {/*            <div className="card border-0 pb-0">*/}
                                {/*                <div className="card-body border-top shadow rounded">*/}
                                {/*                    <Step2Example*/}
                                {/*                        wordsData={wordsData}*/}
                                {/*                        setWordsData={(item) =>*/}
                                {/*                            setWordsData(item)*/}
                                {/*                        }*/}
                                {/*                        goNextStep={() => {}}*/}
                                {/*                        goBack={() => {}}*/}
                                {/*                        showNext={false}*/}
                                {/*                    />*/}
                                {/*                    <div className="text-end toolbar toolbar-bottom p-2">*/}
                                {/*                        <button*/}
                                {/*                            className="btn btn-primary sw-btn-prev me-1"*/}
                                {/*                        >*/}
                                {/*                            Update*/}
                                {/*                        </button>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*    </div>*/}
                                {/*</div>}*/}

                                <div
                                    className={
                                        "row d-flex justify-content-end m-1 mb-3"
                                    }
                                >
                                    <a
                                        className={"btn col-2 btn-primary m-1"}
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        Get sentences list
                                    </a>
                                </div>
                                <div className={"col-lg-12 col-sm-12"}>
                                    <div className="w-100 mb-3">
                                        <div className="form-group mb-3">
                                            <div className="card border-0 pb-0">
                                                <div className="card-body border-top shadow rounded">
                                                    <strong
                                                        className={
                                                            "text-primary fs-16"
                                                        }
                                                    >
                                                        Sentences
                                                    </strong>
                                                    <PerfectScrollbar
                                                        style={{
                                                            height: "370px",
                                                        }}
                                                        id="DZ_W_Todo2"
                                                        className="widget-media overflow-auto height370 ps ps--active-y mt-3"
                                                    >
                                                        <ul className="timeline">
                                                            {wordsData.map(
                                                                (item, i) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                i
                                                                            }
                                                                            className={
                                                                                "cursor-pointer"
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    "timeline-panel"
                                                                                }
                                                                            >
                                                                                <div className="media-body">
                                                                                    <h5 className="mb-1">
                                                                                        {i +
                                                                                            1 +
                                                                                            ". " +
                                                                                            item}
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </PerfectScrollbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    className="modal fade"
                    size={"lg"}
                    show={showModal}
                    onHide={setShowModal}
                >
                    <div className="" role="document">
                        <div className="">
                            <form>
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">
                                        Fast way
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowModal(false)}
                                        data-dismiss="modal"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">
                                                    Your list
                                                </label>
                                                <div className="contact-name">
                                                    <textarea
                                                        rows="23"
                                                        name="words"
                                                        className="form-control mb-3"
                                                        defaultValue={wordsData
                                                            .toString()
                                                            .replace(",", "\n")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </section>
        </>
    );

    function updateDownloadCount() {
        let data = { ref_id };
        updateDownloadCountCall(data)
            .then((results) => {})
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function getOrder() {
        let data = { ref_id };
        retrieveOrderCall(data)
            .then((results) => {
                if (hasAValue(results.data)) {
                    console.log(results.data);
                    setItem(results.data);
                    setWordsData(results.data.data);
                    getTranslation(results.data.id);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    async function getTranslation(id) {
        await translationCall({
            session_id: id,
        }).then((results) => {
            if (hasAValue(results.data.translation)) {
                setDownloadEnable(true);
                setDownloadLink(getDownloadLink(results.data.translation));
            }
        });
    }

    function getDownloadLink(jsonObject) {
        const json = JSON.stringify(jsonObject, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        return URL.createObjectURL(blob);
    }
}
