import React, { useState } from "react";
import "../../style/css/custom.css";
import NewOrderExample from "../../pages/externalPages/OrderFromWizard/NewOrderExample";

export default function ExampleTestHomepage() {
    return (
        <div id="try-out" className="col-12 bgl-primary p-5">
            <NewOrderExample />
        </div>
    );
}
