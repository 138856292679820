import React from "react";
import Languages from "../../CustomDB/Languages.json";
import Select from "react-select";
import { toast } from "react-toastify";
import { appErrorMessages, toastOptions } from "../../config/Constants";
import va from "simple-react-lightbox";
import {Alert} from "react-bootstrap";

export default function Step3Example({
    targetLanguages,
    setTargetLanguages,
    goNextStep,
    goBack,
}) {
    const CustomClearText = () => "Clear all";
    const ClearIndicator = (props) => {
        const {
            children = <CustomClearText />,
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles("clearIndicator", props)}
            >
                <div style={{ padding: "0px 5px" }}>{children}</div>
            </div>
        );
    };

    const ClearIndicatorStyles = (base, state) => ({
        ...base,
        cursor: "pointer",
        color: state.isFocused ? "blue" : "black",
    });

    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2">
                        <h2 className="text-left">Target languages</h2>
                        <div className="form-group mb-3">
                            <div className="form-group mb-3">
                                <label className="text-label">
                                    Select the languages to which your texts
                                    should be translated. This has to be one at
                                    least but can be as many as desired.
                                </label>
                            </div>
                              <Alert variant="primary" className="solid alert-square">{appErrorMessages.only_2_languages}</Alert>

                            <Select
                                closeMenuOnSelect={false}
                                components={{ ClearIndicator }}
                                styles={{
                                    clearIndicator: ClearIndicatorStyles,
                                }}
                                isMulti
                                required
                                defaultValue={targetLanguages}
                                placeholder="Select your target languages"
                                options={Languages.languages}
                                onChange={(val) => {
                                    if(val.length > 2){
                                        val.shift()
                                    }
                                    setTargetLanguages(val);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button
                    className="btn btn-secondary sw-btn-prev me-1"
                    onClick={() => goBack()}
                >
                    Prev
                </button>
                <button className="btn btn-primary sw-btn-next ms-1"
                    onClick={() => {
                        goNext();
                    }}
                >
                    Confirm and translate
                </button>
            </div>
        </>
    );

    function goNext() {
        if (targetLanguages.length > 0) {
            goNextStep();
        } else {
            toast.error(
                appErrorMessages.choose_language_required,
                toastOptions
            );
        }
    }
}
