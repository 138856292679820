import {format} from 'date-fns';
import {ColumnFilter} from './ColumnFilter';
import {
    convertDateToView,
    convertStatusToView,
    convertTranslatedToView,
    getLanguageLabelByLanguageCode, hasAValue
} from "../../utils/SharedFunctions";
import React from "react";

export const col_order = [
    {
        Header: 'Order No.',
        Footer: 'Order No.',
        accessor: 'ref_id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Source language',
        Footer: 'Source language',
        accessor: 'source_lang',
        Cell: ({value}) => {
            return getLanguageLabelByLanguageCode(value) ?? "No language"
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Target language',
        Footer: 'Target language',
        accessor: 'target_langs',
        Cell: ({value}) => {
            return hasAValue(value) && value.map(
                (lang, i) => {
                    return (
                        <span key={i}>
                            {getLanguageLabelByLanguageCode(lang) + " " ?? "No language "}
                        </span>
                    );
                }
            )
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Amount',
        Footer: 'Amount',
        accessor: 'amount_total',
        Cell: ({value}) => {return value/100},
        Filter: ColumnFilter,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'status',
        Cell: ({value}) => {
            return convertStatusToView(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Translated',
        Footer: 'Translated',
        accessor: 'translated',
        Cell: ({value}) => {
            return convertTranslatedToView(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'created_at',
        Filter: ColumnFilter,
        Cell: ({value}) => {
            return convertDateToView(value)
        },
    },
]

