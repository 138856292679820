import React, { useEffect, useState, Fragment } from "react";
import "../../style/css/custom.css";
import HeaderExternal from "../../components/nav/HeaderExternal";
import WelcomeSection from "../../components/Dashboard/WelcomeSection";
import ShortInfoSection from "../../components/Dashboard/ShortInfoSection";
import InfoSection from "../../components/Dashboard/InfoSection";
import AboutUsSection from "../../components/Dashboard/AboutUsSection";
import Footer from "../../components/Footer";
import PricingSection from "../../components/Dashboard/PricingSection";
import ExampleTestHomepage from "../../components/Dashboard/ExampleTestHomepage";
import ContactForm from "../../components/Dashboard/ContactForm";
import CodeSandBoxExample from "../../components/Dashboard/CodeSandBoxExample";

export default function HomePage() {
    return (
        <Fragment>
            <HeaderExternal />
            <div className="h-100 p-meddle background-primary row">
                <WelcomeSection />
                <ShortInfoSection />
                <ExampleTestHomepage />
                <InfoSection />
                <CodeSandBoxExample />
                <PricingSection />
                {/*<AboutUsSection />*/}
                <ContactForm />
                <Footer />
            </div>
        </Fragment>
    );
}
