import React from "react";
import "../../style/css/custom.css";

export default function CodeSandBoxExample() {
    return (
        <div className="col-12 bgl-warning p-5">
            <div className="row justify-content-center">
                <div className={"col-md-4 col-sm-12 justify-content-center d-flex align-items-center"}>
                    <h1 className="text-center">Real implementation</h1>
                </div>
                <div className={"col-md-8 col-sm-12"}>
                    <iframe
                        src="https://codesandbox.io/embed/translatedev-zvkbnn?fontsize=14&hidenavigation=1&theme=dark"
                        title="TranslateDev"
                        className={"sandbox-layout"}
                        allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                    />
                </div>
            </div>
        </div>
    );
}
