import React from "react";
import "../../style/css/custom.css";
import { Link } from "react-router-dom";
import logo from "./../../style/images/favicon.png";
import Typewriter from "typewriter-effect";

export default function WelcomeSection() {
    return (
        <>
            <div className="col-12 h-100 homepage-3">
                <section
                    id="home"
                    className="section welcome-area row justify-content-center h-75 align-items-center"
                >
                    <div className="col-md-5">
                        <div className="form-input-content text-center error-page">
                            <h1 className="error-text font-weight-bold">
                                <img
                                    className="logo"
                                    src={logo}
                                    alt=""
                                    width={250}
                                />
                                <h4 className={"fw-bold"}>Translate Dev</h4>
                            </h1>
                            <h4>
                                Built for translating{" "}
                                <Typewriter
                                    options={{
                                        strings: [
                                            "your website",
                                            "your app",
                                            "your system",
                                            "your content",
                                        ],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </h4>

                            <div>
                                <a
                                    className="btn btn-primary rounded-pill border-white border-3 text-white m-3 ps-5 pe-5 p-3 fw-bold"
                                    href="#try-out"
                                >
                                    Try Now{" "}
                                </a>
                                <a
                                    className="btn btn-primary rounded-pill border-white border-3 text-white m-3 ps-5 pe-5 p-3 fw-bold"
                                    href="#learn-more"
                                >
                                    Learn more
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Shape Bottom */}
                    <div className="shape-bottom">
                        <svg
                            fill="#fff"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1921 819.8"
                            style={{ enableBackground: "new 0 0 1921 819.8" }}
                            xmlSpace="preserve"
                        >
                            <path
                                className="st0"
                                d="M1921,413.1v406.7H0V0.5h0.4l228.1,598.3c30,74.4,80.8,130.6,152.5,168.6c107.6,57,212.1,40.7,245.7,34.4  c22.4-4.2,54.9-13.1,97.5-26.6L1921,400.5V413.1z"
                            />
                            <path
                                className="st1"
                                d="M1921,387.9v431.9H0V0h13l226.6,594.4c14.4,35.7,34,67.4,58.3,94.3c24.4,26.9,54.2,49.8,88.6,68.1  c50.2,26.6,105.8,40.1,165.2,40.1c36.1,0,63.6-5.2,72.6-6.9c21.6-4,53.9-12.9,96.1-26.2L1921,387.9z"
                            />
                        </svg>
                    </div>
                </section>
            </div>
        </>
    );
}
