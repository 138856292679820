import React, {Fragment, useEffect, useState} from "react";
import Swal from "sweetalert2";
import { appUrls } from "../../config/Constants";
import "../../style/css/custom.css";
import {hasAValue} from "../../utils/SharedFunctions";

export default function ContactForm() {
    const [from, setFrom] = useState(undefined)
    const [subject, setSubject] = useState(undefined)
    const [messageString, setMessageString] = useState(undefined)

    function sendMail() {
        if (hasAValue(from) && hasAValue(subject) && hasAValue(messageString)) {
            // Send mail
            fetch(appUrls.api_backend + "contact-email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: from,
                    subject: subject,
                    message: messageString,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data?.success) {
                        Swal.fire({
                            title: "Success",
                            text: "Your message has been sent",
                            icon: "success",
                            confirmButtonText: "Ok",
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Something went wrong",
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                    }
                });
        } else {
            Swal.fire({
                title: "Error",
                text: "Please fill in all fields",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    }

    return (
        <div id="contact-email" className="col-12 bgl-primary p-5">
            <Fragment>
                <div className="row" id="contact-email">
                    <div className="col-lg-12">
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className={"p-4 text-center"}>
                                    <h1>Contact us</h1>
                                    <h4>
                                        Do you want to ask us something? Or do you have a suggestion?
                                        <br/>
                                        Please fill in the form below and we will get back to you asap.
                                    </h4>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="email-right-box ms-0 ms-sm-4 ms-sm-0">
                                        <div className="compose-content">
                                            <form action="">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">From </label>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        maxLength={80}
                                                        value={from}
                                                        onChange={(val)=> setFrom(val.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Subject </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength={100}
                                                        value={subject}
                                                        onChange={(val)=> setSubject(val.target.value)}

                                                    />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <div className={"d-flex justify-content-between"}>
                                                        <label className="text-label">Your question </label>
                                                        <label className="text-label">{hasAValue(messageString) ? messageString.toString().length : 0}/400</label>
                                                    </div>

                                                    <textarea
                                                        className="form-control"
                                                        rows="8"
                                                        maxLength={400}
                                                        value={messageString}
                                                        onChange={(val)=> {
                                                            setMessageString(val.target.value)
                                                        }}

                                                    />
                                                </div>
                                            </form>

                                        </div>
                                        <div className="text-left mt-4 mb-5">
                                            <button
                                                className="btn btn-primary btn-sl-sm me-2"
                                                type="button"
                                                onClick={sendMail}>
                                                    <span className="me-2">
                                                        <i className="fa fa-paper-plane"></i>
                                                    </span>
                                                Send
                                            </button>
                                            <button
                                                className="btn btn-danger light btn-sl-sm"
                                                type="button"
                                                onClick={
                                                    ()=> {
                                                        setFrom("")
                                                        setSubject("")
                                                        setMessageString("")
                                                    }
                                                }>
                                                    <span className="me-2">
                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                    </span>
                                                Discard
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    );
}
