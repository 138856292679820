import React, {useState} from 'react'
import {Link} from 'react-router-dom'

// image
import logo from "../../../style/images/favicon.png";
import HeaderExternal from "../../../components/nav/HeaderExternal";
import {auth1Call, auth2Call, signinCall} from "../../../Api/ApiCalls";
import {hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import {appErrorMessages, appLocalStorageValues, toastOptions} from "../../../config/Constants";
import {saveDataLocalStorage} from "../../../utils/LocalStorageFunctions";

export default function Login() {
    const [email, setEmail] = useState(undefined);
    const [code, setCode] = useState(undefined);

    const [lockEmail, setLockEmail] = useState(false);
    const [signInOn, setSignInOn] = useState(true);


    return (
        <>
            <HeaderExternal/>
            <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="login-aside text-center flex-column flex-row-auto d-flex justify-content-center">
                    <div className="d-flex flex-column-auto flex-column ">
                        <div className="text-center mb-4 ">
                            <img src={logo} className={"login-logo"} alt=""/>
                        </div>
                        <h3 className="mb-2">Welcome 😁!</h3>
                    </div>
                </div>
                <div
                    className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                    <div className="d-flex justify-content-center h-100 align-items-center">
                        <div className="authincation-content style-2">
                            <div className="row no-gutters">
                                <div className="col-xl-12 tab-content">
                                    <div id="sign-in" className="auth-form form-validation">
                                        <div className="form-validate">
                                            <h3 className="text-center mb-4 text-black">{signInOn ? "Sign in" : "Sign up"}</h3>
                                            <div className="form-group mb-3">
                                                <label className="mb-1"
                                                       htmlFor="val-email"><strong>Email</strong></label>
                                                <div>
                                                    <input type="email" className={lockEmail?"form-control bg-light" : "form-control"}
                                                           name={"email"}
                                                           disabled={lockEmail}
                                                           value={email}
                                                           onChange={(e) => setEmail(e.target.value)}
                                                           onKeyPress={(val) => {
                                                               if (val.key === "Enter") {
                                                                   if (!lockEmail) auth1()
                                                                   else auth2()
                                                               }

                                                           }}
                                                           placeholder="Type Your Email Address"
                                                    />
                                                </div>
                                            </div>
                                            {lockEmail && <div className="form-group mb-3">
                                                <label className="mb-1"><strong>Code</strong></label>
                                                <input
                                                    type="text"
                                                    name={"code"}
                                                    className="form-control"
                                                    value={code}
                                                    placeholder="Code"
                                                    onKeyPress={(val) => {
                                                            if (val.key === "Enter") {
                                                                if (!lockEmail) auth1()
                                                                else auth2()
                                                            }

                                                    }}
                                                    onChange={(e) =>
                                                        setCode(e.target.value)
                                                    }
                                                />
                                            </div>}
                                            <div className="text-center form-group mb-3">
                                                <button className="btn btn-primary btn-block"
                                                        onClick={() => {
                                                            if (!lockEmail) auth1()
                                                            else auth2()
                                                        }}>
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                        <div className="new-account mt-3">
                                            <p><a className="text-primary" href={"#"}
                                                  onClick={() => setSignInOn(!signInOn)}>{!signInOn ? "or Sign in" : "or Sign up"}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function auth1() {
        let data = {email}
        if (hasAValue(email)) {
            auth1Call(data).then(success => {
                setLockEmail(true)
                toast.success(success.data.message, toastOptions);
            }).catch(error => {
                toast.error(error.response.data.error, toastOptions);
            })
        } else {
            toast.error(appErrorMessages.all_fields_are_required, toastOptions);
        }
    }

    function auth2() {
        let data = {email, code}
        if (hasAValue(code) && hasAValue(email)) {
            auth2Call(data).then(success => {
                toast.success(success.data.message, toastOptions);
                saveDataLocalStorage(appLocalStorageValues.user_data, {
                    access_token: success.data.access_token,
                    refresh_token: success.data.refresh_token,
                    user_type: success.data.user_type
                })

                window.location = "/dashboard"
            }).catch(error => {
                toast.error(error.response.data.error, toastOptions);
            })
        } else {
            toast.error(appErrorMessages.all_fields_are_required, toastOptions);
        }
    }
};
