import React from "react";
import "../../style/css/custom.css";

export default function PricingSection() {
    return (
        <div className="col-12 background-secondary p-5 mb-5">
            <div className="row justify-content-center">
                <div className={"col-lg-7"}>
                    <div className="row justify-content-center">
                        <h1 className="text-center">Pricing</h1>
                        <div className="d-lg-flex justify-content-center">
                            <div
                                className="card shadow m-lg-2 card-flex"
                                style={{ height: "fit-content" }}
                            >
                                <div className="card-header justify-content-center bg-primary">
                                    <h1 className="fw-bold text-white">
                                        €10/language
                                    </h1>
                                </div>
                                <div className="card-body p-0 ">
                                    <ul className="list-group list-group-flush text-center">
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {"Maximum of 10,000 words"}
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {"Maximum of 40,000 characters"}
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {"100+ supported languages"}
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {
                                                    "Translated in less than 5 minutes"
                                                }
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {"More than 70% time saving"}
                                            </h4>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="card shadow m-lg-2 card-flex"
                                style={{ height: "fit-content" }}
                            >
                                <div className="card-header justify-content-center bg-primary">
                                    <h1 className="fw-bold text-white">
                                        Custom €?
                                    </h1>
                                </div>
                                <div className="card-body p-0 ">
                                    <ul className="list-group list-group-flush text-center">
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {
                                                    "We take care of your site's translation"
                                                }
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {
                                                    "We will edit your site and make it multilingual"
                                                }
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                    For more information <a href='#contact-email'> <b>contact us</b></a>
                                            </h4>
                                        </li>
                                        <li className="list-group-item border">
                                            <h4 className="text-black ms-2">
                                                {"We will be happy to help you out"}
                                            </h4>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
