import React, { Fragment, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import Step1 from "../../../components/NewOrderWizard/Step1";
import Step2 from "../../../components/NewOrderWizard/Step2";
import Step3 from "../../../components/NewOrderWizard/Step3";
import {
    getUserData,
    hasAValue,
    } from "../../../utils/SharedFunctions";
import { toast } from "react-toastify";
import { appErrorMessages, toastOptions } from "../../../config/Constants";
import {
    newOrderCall
} from "../../../Api/ApiCalls";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Step4 from "../../../components/NewOrderWizard/Step4";

export default function NewOrder() {
    const { search } = useLocation();
    const values = queryString.parse(search);
    const [goSteps, setGoSteps] = useState(1);
    const [sourceLanguage, setSourceLanguage] = useState(undefined);
    const [wordsData, setWordsData] = useState([]);
    const [targetLanguages, setTargetLanguages] = useState([]);
    const [termsAccept, setTermsAccept] = useState(false);

    const [userData, setUserData] = useState({});
    const [orderStatus, setOrderStatus] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function setUserAsync() {
            setUserData(await getUserData());
        }
        setUserAsync();
    }, []);


    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Order a translation</h4>
                        </div>
                        <div className="card-body">
                            <div className="form-wizard ">
                                {goSteps !== 5 && (
                                    <Stepper
                                        className="nav-wizard"
                                        activeStep={goSteps - 1}
                                        label={false}
                                    >
                                        <Step
                                            className="nav-link"
                                            onClick={() =>{hasAValue(loading)? setGoSteps(1) : console.log("")} }
                                        />
                                        <Step
                                            className="nav-link"
                                            onClick={() =>{hasAValue(loading)? setGoSteps(2) : console.log("")} }
                                        />
                                        <Step
                                            className="nav-link"
                                            onClick={() =>{hasAValue(loading)? setGoSteps(3) : console.log("")} }
                                        />
                                        <Step
                                            className="nav-link"
                                            style={{ "border-style": "none" }}
                                        />
                                    </Stepper>
                                )}

                                {/*sourceLanguage*/}
                                {goSteps === 1 && (
                                    <Step1
                                        sourceLanguage={sourceLanguage}
                                        setSourceLanguage={(item) =>
                                            setSourceLanguage(item)
                                        }
                                        goNextStep={() => goNextStep()}
                                    />
                                )}

                                {/*wordsData*/}
                                {goSteps === 2 && (
                                    <Step2
                                        wordsData={wordsData}
                                        setWordsData={(item) =>
                                            setWordsData(item)
                                        }
                                        goNextStep={() => goNextStep()}
                                        goBack={() => goBack()}
                                        showNext={true}
                                    />
                                )}

                                {/*targetLanguages*/}
                                {goSteps === 3 && (
                                    <Step3
                                        targetLanguages={targetLanguages}
                                        setTargetLanguages={(item) =>
                                            setTargetLanguages(item)
                                        }
                                        goNextStep={() => goNextStep()}
                                        goBack={() => goBack()}
                                    />
                                )}

                                {/*Check out*/}
                                {goSteps === 4 && (
                                    <>
                                        <Step4 texts={wordsData} targetLanguages={targetLanguages}
                                               sourceLanguage={sourceLanguage}
                                               loading={loading}
                                               setTermsAccept={()=> setTermsAccept(!termsAccept)}
                                               termsAccept={termsAccept}
                                               goNextStep={() => goNextStep()}
                                               goBack={() => goBack()}
                                        />
                                    </>
                                )}

                                {/*/!*Thanks*!/*/}
                                {/*{goSteps === 5 && (*/}
                                {/*    <>*/}
                                {/*        <LastStepExample order={orderObject} />*/}
                                {/*    </>*/}
                                {/*)}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    function goNextStep() {
        if (goSteps === 4) {
            newOrder();
        } else {
            setGoSteps(goSteps + 1);
        }
    }

    function goBack() {
        setGoSteps(goSteps - 1);
    }

    function newOrder() {
        // console.log("newOrder");
        if (hasAValue(orderStatus) && orderStatus === "exists") {
            // go to next step
            setGoSteps(goSteps + 1);
        }
        if (
            hasAValue(sourceLanguage) &&
            hasAValue(wordsData) &&
            hasAValue(targetLanguages)
        ) {
            let targets = targetLanguagesToListOfCodes();
            let texts = wordsData;
            let data = { sourceLanguage, texts, targets };
            setLoading(true)
            newOrderCall(data).then((results) => {
                window.open(results.data.paymentLink)
                window.location = "/dashboard"
                })
                .catch((error) => {
                    toast.error(error.response.data.error, toastOptions);
                });
        } else {
            toast.error(appErrorMessages.all_fields_are_required, toastOptions);
        }
    }

    // function retrieveOrder(ref_id) {
    //     let data = { ref_id: ref_id };
    //     retrieveOrderCall(data)
    //         .then((results) => {
    //             console.log(results)
    //             results = results?.data ?? {};
    //             console.log("results", results);
    //             setOrder(results);
    //             setOrderObject(results);
    //         })
    //         .catch((error) => {
    //             toast.error(error.response.data.error, toastOptions);
    //         });
    // }
    //
    // function setOrder(order) {
    //     setWordsData(order?.data ?? []); // retrieve data and parse json string to array
    //     setSourceLanguage(order?.source_lang);
    //     setTargetLanguages(order?.target_langs);
    //     setPaymentLink(order?.payment_link);
    //     setOrderStatus("exists");
    //
    //     if (order?.status === "awaiting_payment") {
    //         setGoSteps(4);
    //         toast.error("Payment has failed, please try again", {
    //             position: "top-right",
    //             autoClose: false,
    //             hideProgressBar: false,
    //             pauseOnHover: false,
    //             draggable: false,
    //             progress: undefined,
    //             limit: 5,
    //         });
    //     } else if (order?.status === "payment_received") {
    //         setGoSteps(5);
    //     }
    // }

    function targetLanguagesToListOfCodes() {
        let targets = targetLanguages.map((item) => {
            return item?.value ?? "";
        });
        return targets;
    }

    // function getPaymentLink() {
    //     let targets = targetLanguagesToListOfCodes();
    //     let texts = wordsData;
    //     let data = { sourceLanguage, texts, targets };
    //     getPaymentLinkCall(data)
    //         .then((results) => {
    //             console.log(results);
    //             window.location.href = results?.data?.paymentLink ?? "";
    //         })
    //         .catch((error) => {
    //             toast.error(error.response.data.error, toastOptions);
    //         });
    // }
}
