import React from "react";
import Languages from "../../CustomDB/Languages.json";
import { hasAValue } from "../../utils/SharedFunctions";
import { toast } from "react-toastify";
import { appErrorMessages, toastOptions } from "../../config/Constants";

export default function Step1({
    sourceLanguage,
    setSourceLanguage,
    goNextStep,
}) {
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2">
                        <h2 className="text-left">Source language</h2>
                        <div className="form-group mb-3">
                            <div className="form-group mb-3">
                                <label className="text-label">
                                    Select the language from which your texts
                                    should be translated.
                                </label>
                            </div>
                            <select
                                required
                                defaultValue={sourceLanguage}
                                className="form-control form-control-md"
                                onChange={(name) => {
                                    setSourceLanguage(name.target.value);
                                }}
                            >
                                <option>Select a language</option>
                                {Languages.languages.map((item, index) => {
                                    return (
                                        <option value={item.value} key={index}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button
                    className="btn btn-primary sw-btn-next"
                    onClick={() => goNext()}
                >
                    Next
                </button>
            </div>
        </>
    );

    function goNext() {
        if (hasAValue(sourceLanguage)) {
            goNextStep();
        } else {
            toast.error(
                appErrorMessages.choose_language_required,
                toastOptions
            );
        }
    }
}
