import React from "react";
import "../../style/css/custom.css";


export default function ShortInfoSection() {
    return (
        <div className="col-12 pt-5 bg-white ">
            <div className="row justify-content-center h-100 align-items-center ptb_100">
                <div className="col-md-10 row">
                    <div className="col-md-3 col-sm-6">
                        <div className="widget-stat">
                            <div className="card-body p-4">
                                <div className="media ai-icon justify-content-center">
                                            <span className="me-3 bgl-primary text-primary">
                                                <i className="fas fa-pen-fancy"></i>
                                            </span>
                                </div>
                                <div className="media-body text-center pt-2 ps-5 pe-5">
                                    <h3 className="mb-0">Efficient</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="widget-stat">
                            <div className="card-body p-4">
                                <div className="media ai-icon justify-content-center">
                                            <span className="me-3 bgl-success text-success">
                                                <i className="fas fa-paint-brush"></i>
                                            </span>
                                </div>
                                <div className="media-body text-center pt-2 ps-5 pe-5">
                                    <h3 className="mb-0">Easy to use</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="widget-stat">
                            <div className="card-body p-4">
                                <div className="media ai-icon justify-content-center">
                                            <span className="me-3 bgl-danger text-danger">
                                                <i className="fas fa-business-time"></i>
                                            </span>
                                </div>
                                <div className="media-body text-center pt-2 ps-5 pe-5">
                                    <h3 className="mb-0">70% Time saving</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="widget-stat">
                            <div className="card-body p-4">
                                <div className="media ai-icon justify-content-center">
                                            <span className="me-3 bgl-warning text-warning">
                                                <i className="fas fa-file-alt"></i>
                                            </span>
                                </div>
                                <div className="media-body text-center pt-2 ps-5 pe-5">
                                    <h3 className="mb-0">Well documented</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
