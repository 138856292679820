import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
    var d = new Date();
    return (
        <div className="footer">
            <div className={"d-flex justify-content-center"}>
                <div className="copyright">
                    <p className="fs-14 fw-bold text-center">Made with <span className="heart"></span> by <a
                        href="http://tagdev.nl" target="_blank" rel="noreferrer">Translate Dev Team</a></p>
                </div>

                <div className="copyright">
                    <p className="fs-14 fw-bold text-center"><Link to="/page-disclaimer" target="_blank" rel="noreferrer">Terms
                        & conditions</Link></p>
                </div>

            </div>
        </div>
    );
};

export default Footer;
