import axios from "axios";
import { getDataLocalStorage } from "../utils/LocalStorageFunctions";
import { appLocalStorageValues } from "../config/Constants";
import { hasAValue } from "../utils/SharedFunctions";

export const apiGet = async (
    route,
    options = {},
    headersReceived = (headers) => {}
) => {
    let user_data = await getDataLocalStorage(appLocalStorageValues.user_data);
    let accessToken = "test";
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token;
    }
    return axios
        .get(route, {
            headers: {
                Authorization: "bearer " + accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route);
            throw error;
        });
};

export const apiDelete = async (route, data = {}) => {
    let user_data = await getDataLocalStorage(appLocalStorageValues.user_data);
    let accessToken = "test";
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token;
    }
    return axios
        .post(route, data, {
            headers: {
                Authorization: "bearer " + accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("Error:  ", route);
            throw error;
        });
};

export const apiPut = async (route, data, options = {}) => {
    let user_data = await getDataLocalStorage(appLocalStorageValues.user_data);
    let accessToken = "test";
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token;
    }
    return axios
        .put(route, data, {
            headers: {
                Authorization: "bearer " + accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("Error:  ", route);
            throw error;
        });
};

export const apiPost = async (route, data, options = {}) => {
    let user_data = await getDataLocalStorage(appLocalStorageValues.user_data);

    let accessToken = "test";
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token;
    }

    return axios
        .post(route, data, {
            headers: {
                Authorization: "bearer " + accessToken,
                "Content-Type": "application/json",
                // 'Access-Control-Allow-Origin': '*',
            },
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route);
            throw error;
        });
};
