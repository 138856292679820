import React, {useEffect, useState} from "react";
import {hasAValue} from "../../utils/SharedFunctions";
import {toast} from "react-toastify";
import {appErrorMessages, toastOptions} from "../../config/Constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Alert, Modal} from "react-bootstrap";

export default function Step2Example({wordsData, setWordsData, goNextStep, goBack, showNext}) {
    const [newWord, setNewWord] = useState(undefined);
    const [placeHolder, setPlaceHolder] = useState(undefined);

    const [showModal, setShowModal] = useState(false);
    const [fastList, setFastList] = useState([]);
    // const [fastListInputType, setFastListInputType] = useState("list");


    useEffect(async () => {
        window.addEventListener("focus", () => {
            window.navigator.clipboard.readText().then((r) => {
                setPlaceHolder(r);
            });
        });
    }, [window.navigator.clipboard]);

    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-10 mb-2">
                        <div className="form-group mb-3">
                            <div className={"d-flex justify-content-between mb-3"}>
                                <h2 className="text-left">Input your texts here</h2>
                                <h2 className="text-right text-primary">{wordsData.length} Sentences</h2>
                            </div>

                            {/* Here comes a subtitle for explanation */}
                            <div className="form-group mb-1">
                                <label className="text-label">
                                    Paste your texts here, one text per line.
                                    After you've pasted your texts, you can add
                                    another text by pressing the <a className={"fw-bold"}>enter key</a>.
                                    Texts will be pasted automatically when you
                                    click in the text area.
                                </label>
                            </div>

                            { wordsData.length === 5 &&  <Alert variant="primary" className="solid alert-square">{appErrorMessages.only_5_sentences}</Alert>}

                            <div className={"mb-5"}>
                                <div className="card border-0 pb-0">
                                    <div className="card-body border-top shadow rounded">
                                        <label className="text-label">Sentence</label>

                                        { (wordsData.length < 5 && hasAValue(placeHolder)) &&  <div className={"row d-flex justify-content-between align-items-center mt-4 mb-4"}>
                                            <i className={"col-lg-6 col-sm-12 border p-2 rounded text-warning"}>{placeHolder}</i>

                                            <i className={"col-lg-2 col-sm-6 fa fa-check fs-22 text-success cursor-pointer"} onClick={() => {
                                                let array = wordsData;
                                                array.push(placeHolder);
                                                setWordsData(array);
                                                setPlaceHolder(undefined)
                                            }}><a className={"fs-16"}>Accept and add</a></i>

                                            <i className={"col-lg-2 col-sm-6 fa fa-pen fs-22 text-warning cursor-pointer"} onClick={() => {
                                                setNewWord(placeHolder)
                                                setPlaceHolder(undefined)
                                            }}><a className={"fs-16"}>Accept and change</a></i>

                                            <i className={"col-lg-2 col-sm-6 fa fa-times fs-22 text-danger cursor-pointer"} onClick={() => {
                                                setPlaceHolder(undefined)
                                            }}><a className={"fs-16"}> Don't accept</a></i>
                                        </div>}


                                        {wordsData.length < 5 &&  <input
                                            type="text"
                                            name="text"
                                            className="form-control"
                                            onChange={(val) => setNewWord(val.target.value)}
                                            defaultValue={newWord}
                                            value={newWord}
                                            onKeyPress={(val) => {
                                                if (hasAValue(newWord) && newWord !== "") {
                                                    let array = wordsData;
                                                    if (val.key === "Enter") {
                                                        array.push(newWord);
                                                        setWordsData(array);
                                                        setNewWord("");
                                                    }
                                                }
                                            }}
                                            required
                                        />}

                                            <ul className="timeline">
                                                {wordsData.map((item, i) => {
                                                    let changedValue = item;
                                                    return (
                                                        <div className={"d-flex justify-content-between"}>
                                                            <i className={"fs-22 m-3 mt-4"}>{(i + 1) + "."} </i>
                                                            <input
                                                                type="text"
                                                                name="firstName"
                                                                className="form-control mt-3 mb-3"
                                                                defaultValue={item}
                                                                onChange={(val) => {
                                                                    changedValue = val.target.value;
                                                                    if (
                                                                        hasAValue(changedValue) &&
                                                                        changedValue !== ""
                                                                    ) {
                                                                        let array = wordsData;
                                                                        array[i] = changedValue;
                                                                        setWordsData(array);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                            {/*<i className={"fa fa-trash-alt fs-22 m-3 mt-4"} onClick={()=> {*/}
                                                            {/*}}></i>*/}
                                                        </div>
                                                    );
                                                })}
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal className="modal fade" size={"lg"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Fast way</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        {/*<div className="form-group mb-3">*/}
                                        {/*    <label className="text-black font-w500">Input type</label>*/}
                                        {/*    <div className="contact-name">*/}
                                        {/*     <select*/}
                                        {/*         className="form-control mb-3"*/}
                                        {/*         defaultValue={fastList}*/}
                                        {/*         onChange={val => {*/}
                                        {/*             setFastListInputType(val.target.value)*/}
                                        {/*         }}*/}
                                        {/*         required*/}
                                        {/*     >*/}
                                        {/*         <option value={"list"}>List</option>*/}
                                        {/*         <option value={"json"}>Json</option>*/}
                                        {/*     </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-group mb-3">
                                            <label className="text-black font-w500">Your list</label>
                                            <div className="contact-name">
                                             <textarea
                                                 rows="23"
                                                 name="words"
                                                 className="form-control mb-3"
                                                 onChange={val => {
                                                     convertListToList(val.target.value)
                                                 }}
                                                 required
                                             />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    setWordsData(wordsData.concat(fastList))
                                    setShowModal(false)
                                }}>Update
                                </button>
                                <button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i
                                    className="flaticon-delete-1"></i> Wijzigingen negeren
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
            {showNext && <div className="text-end toolbar toolbar-bottom p-2">
                <button
                    className="btn btn-secondary sw-btn-prev me-1"
                    onClick={() => goBack()}
                >
                    Prev
                </button>
                <button
                    className="btn btn-primary sw-btn-next ms-1"
                    onClick={() => {
                        goNext();
                    }}
                >
                    Next
                </button>
            </div>}
        </>
    );

    function goNext() {
        if (wordsData.length > 0) {
            goNextStep();
        } else {
            toast.error(appErrorMessages.words_data_required, toastOptions);
        }
    }

    function convertListToList(items) {
        let array2 = items.toString().split("\n")
        setFastList(array2)
    }

    // function convertJsonToList(items) {
    //     console.log(items)
    //     let itemsString = items.replace(/,/g, ' ')
    //     itemsString = itemsString.replace(/-/g, ' ')
    //     let array2 = itemsString.toString().split(/\s+/)
    //     setFastList(array2)
    // }
    //
    // function isJson(str) {
    //     try {
    //         JSON.parse(str);
    //     } catch (e) {
    //         toast.error(appErrorMessages.json_format_not_good, toastOptions);
    //         return false;
    //     }
    //     return true;
    // }
}
