import "./style/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./style/css/style.css";
import MyRouts from './routes/Routes'
import {withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {useEffect} from "react";

// Initialize Firebase single env variable
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
    useEffect(()=>{
        logEvent(analytics, "open_website")
    },[])

    return (
        <>
            <ToastContainer limit={1}/>
            <MyRouts/>
        </>
    )


}

export default withRouter(App);
