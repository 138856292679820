import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../pages/internalPages/Dashboard/Home";
import Error400 from "../pages/ErrorPages/Error400";
import Error403 from "../pages/ErrorPages/Error403";
import Error404 from "../pages/ErrorPages/Error404";
import Error500 from "../pages/ErrorPages/Error500";
import Error503 from "../pages/ErrorPages/Error503";
import React from "react";
import HomePage from "../pages/externalPages/HomePage";
import Login from "../pages/externalPages/auth/Login";
import NewOrder from "../pages/internalPages/Order/NewOrder";
import {getDataLocalStorage} from "../utils/LocalStorageFunctions";
import {appLocalStorageValues as appLocalStoargeValues} from "../config/Constants";
import {hasAValue} from "../utils/SharedFunctions";
import ThankYou from "../pages/internalPages/Order/ThankYou";
import TryAgain from "../pages/internalPages/Order/TryAgain";
import OrderOverview from "../pages/internalPages/Order/OrderOverview";
import MyInvoices from "../pages/internalPages/Profile/MyInvoices";
import {Disclaimer} from "../pages/externalPages/Disclaimer";

export  function privatePages(){
    let userData = getDataLocalStorage(appLocalStoargeValues.user_data)


    return(
        <Switch>

            {/*publicPages*/}
            <Route exact={true} path='/' component={HomePage}/>
            <Route path='/page-error-404' component={Error404}/>
            {/*{!hasAValue(userData) &&  <Route path='/new-order' component={NewOrderWithRegistration}/>}*/}
            <Route path='/page-error-400' component={Error400}/>
            <Route path='/page-error-403' component={Error403}/>
            <Route path='/page-error-404' component={Error404}/>
            <Route path='/page-error-500' component={Error500}/>
            <Route path='/page-error-503' component={Error503}/>
            <Route path='/page-disclaimer' component={Disclaimer}/>


            {/*in Case of no user*/}
            {!hasAValue(userData) && <Route exact path="/page-login" component={Login} />}


            {/*privatePages*/}
            {hasAValue(userData) && <Route path='/dashboard' component={Home}/>}
            {hasAValue(userData) && <Route path='/new-order' component={NewOrder}/>}
            {hasAValue(userData) && <Route path='/thank-you' component={ThankYou}/>}
            {hasAValue(userData) && <Route path='/try-again' component={TryAgain}/>}
            {hasAValue(userData) && <Route path='/order-overview/:id' component={OrderOverview}/>}

            {/*{hasAValue(userData) && <Route path='/my-profile' component={MyInvoices}/>}*/}



            <Redirect to="/"/>
        </Switch>
    )
}
