import React, {useContext, useEffect, useState} from "react";
import {fetchMyOrdersCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';
import {col_order} from "../../../components/FilteringTable/Columns";
import './../../../components/FilteringTable/filtering.css';
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import { hasAValue } from "../../../utils/SharedFunctions";

export default function Home() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [columns, setColumns] = useState([])

    useEffect(() => {
        launchLoadingModal();
        setColumns(col_order)
        fetchMyOrders();
    }, []);

    useEffect(() => {
        if (!loading) {
            Swal.close();
        }
    },[loading]);  

    function launchLoadingModal(){
        // Show loading Swal
        Swal.fire({
            title: "Loading...",
            allowOutsideClick: true,
            allowEscapeKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
    }


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (


        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Orders</h4>
                    <Link className="btn btn-secondary" to={"new-order"}>+ New order</Link>

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => goOrderOverview(row.original)}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function goOrderOverview(item) {
        window.location ="/order-overview/"+item.ref_id
    }

    async function fetchMyOrders() {
        await fetchMyOrdersCall()
            .then((results) => {
                setLoading(false);

                // For each result json parse the target langs
                results.forEach((item) => {
                    item.target_langs = JSON.parse(item.target_langs);
                });

                setData(results);
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }
}



