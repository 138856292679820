import { apiGet, apiPost } from "./Api";
import { appUrls } from "../config/Constants";

// export async function getStudentCitiesCall() {
//     return apiGet(appUrls.knaek_api + "cities").then(result => {
//         return result;
//     }).catch(err => {
//         throw err;
//     });
// }

export async function signUpCall(data) {
    return apiPost(appUrls.api_backend + "sign_up", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function signUpWithOneCodeCall(data) {
    return apiPost(appUrls.api_backend + "sign_up_one_code", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function signinCall(data) {
    return apiPost(appUrls.api_backend + "login", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function auth1Call(data) {
    return apiPost(appUrls.api_backend + "auth_step1", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function auth2Call(data) {
    return apiPost(appUrls.api_backend + "auth_step2", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function newOrderCall(data) {
    return apiPost(appUrls.api_backend + "new_order", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function newFreeOrderCall(data) {
    return apiPost(appUrls.api_backend + "translate_free", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}
export async function getPaymentLinkCall(data) {
    return apiPost(appUrls.api_backend + "payment_link", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function retrieveOrderCall(data) {
    return apiPost(appUrls.api_backend + "retrieve_order", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function fetchMyOrdersCall(data) {
    return apiGet(appUrls.api_backend + "fetch_my_orders", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function updateDownloadCountCall(data) {
    return apiPost(appUrls.api_backend + "order/download", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function translationCall(data) {
    return apiPost(appUrls.api_backend + "translation", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}
