import React, {useEffect, useState} from "react";

export default function ExampleTestHomepageJson({translatingJson}) {

    return (
        <>
            <section className={"row"}>
                <div className="col-lg-12 mb-2">
                    <div className="form-group mb-3">
                        <div className="contact-name">
                                             <textarea
                                                 rows="24"
                                                 disabled={true}
                                                 name="words"
                                                 className="form-control mb-3 bg-dark text-primary"
                                                 defaultValue={translatingJson}
                                             />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
