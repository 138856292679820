import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExampleTestHomepageJson from "../Dashboard/ExampleTestHomepageComponents/ExampleTestHomepageJson";

export default function Step4Example({texts, translating, goBack, goNextStep, loading}) {
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-10 mb-2 ">
                        <h2 className="text-left">Yes 😁 translating is done 🥳</h2>

                        <div className={"row"}>
                            <div className={"col-lg-6 col-sm-12"}>
                                <div className="w-100 mb-3">
                                    <div className="form-group mb-3">
                                        <div className="card border-0 pb-0">
                                            <div className="card-body border-top shadow rounded">
                                                <strong className={"text-primary"}>Sentences</strong>
                                                <PerfectScrollbar
                                                    style={{height: "370px"}}
                                                    id="DZ_W_Todo2"
                                                    className="widget-media overflow-auto height370 ps ps--active-y mt-3"
                                                >
                                                    <ul className="timeline">
                                                        {texts.map((item, i) => {
                                                            return (
                                                                <li key={i}
                                                                    className={"cursor-pointer"}>
                                                                    <div className={"timeline-panel"}>
                                                                        <div className="media-body">
                                                                            <h5 className="mb-1">{i + 1 + ". " + item}</h5>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-lg-6 col-sm-12"}>
                                <ExampleTestHomepageJson translatingJson={JSON.stringify(translating, null, "\t")}/>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
